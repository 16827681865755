/* You can add global styles to this file, and also import other style files */
@use '@angular/material' as mat;

@import 'theme';
@import 'typography';

html, body {
  height: 100%;
}

body {
  font-weight: 400;
  font-size: 0.875rem;
  line-height: 1.5;
  font-family: Archivo, "Helvetica Neue", sans-serif;
  margin: 0;
}

.sidebar-panel {
  position: fixed;
  top: 0;
  left: 0;
  min-height: 100vh;
  z-index: 10001;
  width: 15rem;
  box-shadow: 0 3px 1px -2px rgba(0, 0, 0, 0.2), 0 2px 2px 0 rgba(0, 0, 0, 0.14),
  0 1px 5px 0 rgba(0, 0, 0, 0.12);
  background-color: mat.get-color-from-palette($default-primary-palette, darker2);
  overflow: hidden;

  .sidebar-hold {
    width: 15rem;
    background-color: mat.get-color-from-palette($default-primary-palette, darker2);
  }

  .navigation-hold {
    position: absolute;
    // height: calc(100% - 56px);
    width: 100%;
    margin-top: 56px;
    background: rgba(255, 255, 255, 0.95);
    left: 0;
  }

  ul {
    margin: 0;
    padding: 0;
    list-style: none;
  }
}

.material-icons.icon-xs {
  font-size: 13px !important;
  line-height: 13px;
  height: 13px;
  width: 13px;
}

.mat-expansion-panel-content {
  font-family: Fusion, "Helvetica Neue", sans-serif;
  font-size: 0.875rem !important;
  line-height: 1.5 !important;
  font-weight: 400;

}


.mat-radio-button.mat-accent .mat-radio-ripple .mat-ripple-element {
  opacity: 0 !important; /*click effect color change*/
  background-color: mat.get-color-from-palette($default-primary-palette, darker2) !important;
}

.mat-radio-button.mat-accent .mat-radio-inner-circle {
  background-color: mat.get-color-from-palette($default-primary-palette, main) !important; /*inner circle color change*/
}

.mat-radio-button.mat-accent.mat-radio-checked .mat-radio-outer-circle {
  border-color: mat.get-color-from-palette($default-primary-palette, main) !important; /*outer ring color change*/
}


/*
.mat-dialog-container{
    position: fixed !important;
    top:10rem !important;
    left:10rem !important;
    max-width: 75% !important;
    max-height: 75% !important;
}*/

.mat-snackbar-ok {
  background-color: #44b044;
  color: #ffffff;
}
